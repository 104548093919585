<template>
    <section class="mt-4">
      <b-tabs>
        <b-tab-item label="Таблица">
          <div class="table-container">
            <b-table
              :data="data"
              :bordered="true"
              :narrowed="true"
              :hoverable="true"
              detailed
              default-sort-direction="desc"
              default-sort="date">

              <b-table-column field="id" label="ID" width="40" numeric centered v-slot="props">
                {{ props.row.id }}
              </b-table-column>

              <b-table-column field="code" label="CODE" width="40" numeric centered v-slot="props">
                {{ props.row.code }}
              </b-table-column>

              <b-table-column field="name" label="NAME" width="40" numeric centered v-slot="props">
                {{ props.row.name }}
              </b-table-column>

              <b-table-column field="active" label="Активно" numeric centered v-slot="props">
                <template v-if="props.row.active == 0">
                  <b-tooltip label="Нет">
                    <font-awesome-icon icon="times" />
                  </b-tooltip>
                </template>
                <template v-else="">
                  <b-tooltip label="Да">
                    <font-awesome-icon icon="check" />
                  </b-tooltip>
                </template>
              </b-table-column>

              <b-table-column field="count_dishes" label="Количество блюд" width="40" numeric centered v-slot="props">
                {{ props.row.count_dishes }}
              </b-table-column>

              <template slot="detail" slot-scope="props">
                <b-table
                  :data="props.row.dishes"
                  :bordered="true"
                  :narrowed="true"
                  :hoverable="true"
                  detailed
                  default-sort-direction="desc"
                  default-sort="date">

                  <b-table-column field="id" label="ID" width="40" numeric centered v-slot="props">
                    {{ props.row.id }}
                  </b-table-column>
                  <b-table-column field="code" label="CODE" width="40" numeric centered v-slot="props">
                    {{ props.row.code }}
                  </b-table-column>
                  <b-table-column field="guid" label="GUID" numeric centered v-slot="props">
                    {{ props.row.guid }}
                  </b-table-column>

                  <b-table-column field="active" label="Активно" numeric centered v-slot="props">
                    <template v-if="props.row.active == 0">
                      <b-tooltip label="Нет">
                        <font-awesome-icon icon="times" />
                      </b-tooltip>
                    </template>
                    <template v-else="">
                      <b-tooltip label="Да">
                        <font-awesome-icon icon="check" />
                      </b-tooltip>
                    </template>
                  </b-table-column>

                  <b-table-column field="name" label="Наименование" centered v-slot="props">
                    {{ props.row.name }}
                  </b-table-column>
                  <!--           <b-table-column field="type" label="Тип подачи" centered v-slot="props">
                    {{ props.row.mvtp_Precision }} {{ props.row.mvtp_Name }}
                    </b-table-column> -->
                  <b-table-column field="price" label="Цена" centered v-slot="props">
                    {{ props.row.price/100 }} Р.
                  </b-table-column>
                  <b-table-column field="modifiers_id" label="Схема модификатора" centered v-slot="props">
                    <template v-if="props.row.modifiers_id">
                      <router-link :to="{name: 'a-modifier', params: {id: props.row.modifiers.id}}">
                        {{ props.row.modischeme }}
                      </router-link>
                    </template>
                  </b-table-column>
                  <b-table-column field="action" label="Действие" centered v-slot="props">
                    <b-tooltip label="Просмотр">
                      <router-link :to="{name: 'a-dish', params: {id: props.row.db_id }}" class="navbar-item">
                        <font-awesome-icon icon="eye" />
                      </router-link>
                    </b-tooltip>
                  </b-table-column>

                </b-table>

              </template>

            </b-table>
          </div>
          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <button class="button is-primary" @click="doUpdate()" v-if="is_deleted == false">
                  Удалить c приложения
                  </button>
                  <button class="button is-primary" @click="doUpdate()" v-if="is_deleted == true">
                  Восстановить в приложение
                  </button>
                </div>
              </div>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="JSON">
            <div class="app-json">
              <vue-json-pretty
                :path="'res'"
                :data="data">
              </vue-json-pretty>
            </div>
          </b-tab-item>
        </b-tabs>
    </section>
</template>


<script>
import { EventBus } from '@/utils'
import { getGroup, toogleGroup } from '@/api/group'
// import { ToastProgrammatic as Toast } from 'buefy'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

export default {
  name: "ModifiersForm",
  components: {
    VueJsonPretty
  },
  data() {
    const data = []

    return {
      data: data,
      is_deleted: false,
      group_id: this.$route.params.id,
    }
  },
  computed: {
    //
  },
  mounted: function() {
    let $this = this

    getGroup(this.group_id, this.$store.state.jwt_user)
      .then(response => {
        let data = response.data;
        $this.total = data.total

        data.data.forEach((item) => {
          this.data.push(item)

          this.is_deleted = item.is_deleted
        })
      })
      .catch(
        this.data = []
      )

    // EventBus.$on('error', (error) => {
    //   $this.pageChange()
    //   this.$toast.open({
    //     // message: `${this.$i18n.t('form.error')} ${error}`,
    //     message: 'error',
    //     type: 'is-danger',
    //     position: 'is-bottom'
    //   })
    // })

    // EventBus.$on('fail', (error) => {
    //   $this.pageChange()
    //   this.$toast.open({
    //     // message: `${this.$i18n.t('form.fail')} ${error}`,
    //     message: 'fail',
    //     type: 'is-danger',
    //     position: 'is-bottom'
    //   })
    // })

  },
  beforeDestroy: function(){
    EventBus.$off('error')
    EventBus.$off('fail')
  },
  methods: {
    getLength(value){
      if (value.length > 0){
        let length = 0

        value.forEach((item) => {
          // length = length + 1

          if (item.modi.length > 0){
            item.modi.forEach(() => {
              length = length + 1
            })
          } 
        })

        return value.length
      }
      else{
        return 0
      }
    },

    doUpdate(){
      toogleGroup(this.group_id, this.$store.state.jwt_user)
        .then(response => {
          let data = response.data;
          console.log(data)
          this.refresh()
        })
        .catch(
          this.refresh()
        )
    },

    refresh(){
      getGroup(this.group_id, this.$store.state.jwt_user)
        .then(response => {
          this.data = []
          let data = response.data;
          this.total = data.total

          data.data.forEach((item) => {
            this.data.push(item)

            this.is_deleted = item.is_deleted
          })
        })
        .catch(
          this.data = []
        )
    }
  }
}
</script>
<style>
</style>